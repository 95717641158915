import React, { useEffect } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrManagerNav from '../../../components/KrManagerNav';
import PlanMobile from '../components/PlanMobile';
import {
	PlanRequest,
	TRADING_PLANS,
	TradingPlan,
	User,
} from '../../../interfaces/User';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { BvTokens } from '../../../interfaces/Auth';
import userService from '../../../services/UserService';
import useApi from '../../../hooks/useApi';
import { toast } from 'react-toastify';
import { useAuthActions } from '../../../hooks/useReduxActions';

const set_plan = (data: { tokens: BvTokens; trading_plan: TradingPlan }) =>
	userService.set_trading_plan(data.tokens, data.trading_plan);

const request_plan = (data: { tokens: BvTokens; trading_plan: TradingPlan }) =>
	userService.request_trading_plan(data.tokens, data.trading_plan);

export const Plan: React.FC = () => {
	const { user_data, tokens } = useAppSelector((state) => state.authReducer);

	const { updateUserData } = useAuthActions();

	const getTitle = (plan: TradingPlan) => {
		if (!user_data) return 'Nil';
		if (user_data.trading_plan === 'None') return 'Request Plan';
		if (user_data.trading_plan === plan) return 'Active';

		if (user_data.plan_request?.plan === plan) return 'Requested';

		if (
			TRADING_PLANS.indexOf(user_data.trading_plan) >
			TRADING_PLANS.indexOf(plan)
		) {
			return 'Nil';
		} else if (
			TRADING_PLANS.indexOf(user_data.trading_plan) <
			TRADING_PLANS.indexOf(plan)
		) {
			return 'Request Upgrade';
		}

		return 'Nil';
	};

	const planApiRequest = useApi<
		{ status: boolean; message: string; data: User },
		{ tokens: BvTokens; trading_plan: TradingPlan }
	>(set_plan);

	const requestPlanApiRequest = useApi<
		{ status: boolean; message: string; data: PlanRequest },
		{ tokens: BvTokens; trading_plan: TradingPlan }
	>(request_plan);

	useEffect(() => {
		if (planApiRequest.error) {
			toast(planApiRequest.error, { type: 'error', theme: 'colored' });
			planApiRequest.reset();
		}

		if (requestPlanApiRequest.error) {
			toast(requestPlanApiRequest.error, { type: 'error', theme: 'colored' });
			requestPlanApiRequest.reset();
		}
	}, [planApiRequest.error, requestPlanApiRequest.error]);

	const onPlanClick = (
		plan: TradingPlan,
		title: 'Request Upgrade' | 'Request Plan'
	) => {
		if (!tokens || planApiRequest.loading || requestPlanApiRequest.loading) {
			return;
		}

		if (title === 'Request Plan') {
			planApiRequest.request({ tokens, trading_plan: plan }).then((data) => {
				if (data && data.status) {
					toast(data.message, { type: 'success', theme: 'colored' });
					updateUserData(data.data);
				}
			});
		} else {
			requestPlanApiRequest
				.request({ tokens, trading_plan: plan })
				.then((data) => {
					if (data && data.status && user_data) {
						toast(data.message, { type: 'success', theme: 'colored' });
						updateUserData({ ...user_data, plan_request: data.data });
					}
				});
		}
	};

	return (
		<>
			<div className='bv-plan-page'>
				<KrLeftSide />

				<div className='w-full'>
					<KrManagerNav />

					<div className='kr-manager-filter'>
						<button
							type='button'
							className='btn btn-autowidth btn-red btn-large'
							name='button'
						>
							Trading Plan
						</button>
					</div>

					<div className='kr-admin-table'>
						<div className='plans'>
							<div className='plan'>
								<h3 className='plan-title'>Sapphire</h3>
								<p className='plan-price'>$1,000.00 </p>
								<ul className='plan-features'>
									<li className='plan-feature'> 2% daily </li>
									<li className='plan-feature'>ATS</li>
								</ul>
								<button
									className='plan-button'
									onClick={() => {
										const title = getTitle('Sapphire-1000');

										if (
											title === 'Request Plan' ||
											title === 'Request Upgrade'
										) {
											onPlanClick('Sapphire-1000', title);
										}
									}}
									disabled={getTitle('Sapphire-1000') === 'Nil'}
								>
									{getTitle('Sapphire-1000')}
								</button>
							</div>

							<div className='plan'>
								<h3 className='plan-title'>Gold</h3>
								<p className='plan-price'>$10,000.00 </p>
								<ul className='plan-features'>
									<li className='plan-feature'>2.5% daily </li>
									<li className='plan-feature'>ATS</li>
								</ul>
								<button
									className='plan-button'
									onClick={() => {
										const title = getTitle('Gold-10000');

										if (
											title === 'Request Plan' ||
											title === 'Request Upgrade'
										) {
											onPlanClick('Gold-10000', title);
										}
									}}
									disabled={getTitle('Gold-10000') === 'Nil'}
								>
									{getTitle('Gold-10000')}
								</button>
							</div>

							<div className='plan'>
								<h3 className='plan-title'>Platinum</h3>
								<p className='plan-price'>$50,000.00 </p>
								<ul className='plan-features'>
									<li className='plan-feature'>3% daily </li>
									<li className='plan-feature'>ATS</li>
								</ul>
								<button
									className='plan-button'
									onClick={() => {
										const title = getTitle('Platinum-50000');

										if (
											title === 'Request Plan' ||
											title === 'Request Upgrade'
										) {
											onPlanClick('Platinum-50000', title);
										}
									}}
									disabled={getTitle('Platinum-50000') === 'Nil'}
								>
									{getTitle('Platinum-50000')}
								</button>
							</div>

							<div className='plan'>
								<h3 className='plan-title'>Diamond</h3>
								<p className='plan-price'>$100,000.00 </p>
								<ul className='plan-features'>
									<li className='plan-feature'>4% daily </li>
									<li className='plan-feature'>ATS (VIP)</li>
								</ul>
								<button
									className='plan-button'
									onClick={() => {
										const title = getTitle('Diamond-100000');

										if (
											title === 'Request Plan' ||
											title === 'Request Upgrade'
										) {
											onPlanClick('Diamond-100000', title);
										}
									}}
									disabled={getTitle('Diamond-100000') === 'Nil'}
								>
									{getTitle('Diamond-100000')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<PlanMobile getTitle={getTitle} onPlanClick={onPlanClick} />
		</>
	);
};
