import { TradingPlan } from '../../../interfaces/User';

interface PlanMobileProps {
	getTitle: (
		plan: TradingPlan
	) => 'Request Upgrade' | 'Request Plan' | 'Nil' | 'Active' | 'Requested';
	onPlanClick: (
		plan: TradingPlan,
		title: 'Request Upgrade' | 'Request Plan'
	) => void;
}

const PlanMobile: React.FC<PlanMobileProps> = ({ getTitle, onPlanClick }) => {
	return (
		<div className='bv-plan-mobile py-3'>
			<div className='blog-wrapper'>
				<div className='container'>
					<div className='row g-3'>
						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Sapphire</u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$1,000.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong></strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>
									<br />
									<br />
									<button
										className='btn btn-bold btn-block btn-outline btn-dark'
										onClick={() => {
											const title = getTitle('Sapphire-1000');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Sapphire-1000', title);
											}
										}}
										disabled={getTitle('Sapphire-1000') === 'Nil'}
									>
										{getTitle('Sapphire-1000')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Gold </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$10,000.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>5</strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>

									<br />
									<br />
									<button
										className='btn btn-bold btn-block btn-outline btn-dark'
										onClick={() => {
											const title = getTitle('Gold-10000');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Gold-10000', title);
											}
										}}
										disabled={getTitle('Gold-10000') === 'Nil'}
									>
										{getTitle('Gold-10000')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Platinum </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$50,000.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>10</strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>

									<br />
									<br />
									<button
										className='btn btn-primary btn-sm'
										onClick={() => {
											const title = getTitle('Platinum-50000');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Platinum-50000', title);
											}
										}}
										disabled={getTitle('Platinum-50000') === 'Nil'}
									>
										{getTitle('Platinum-50000')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Diamond </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$100,000.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>14</strong> Trades/Day
									</p>
									<p>
										<strong>ATS (VIP)</strong>
									</p>
									<br />
									<br />
									<button
										className='btn btn-primary btn-sm'
										onClick={() => {
											const title = getTitle('Diamond-100000');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Diamond-100000', title);
											}
										}}
										disabled={getTitle('Diamond-100000') === 'Nil'}
									>
										{getTitle('Diamond-100000')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlanMobile;
