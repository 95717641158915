import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { useRedirectActions } from "../../../hooks/useReduxActions";

export const TradingPlans: React.FC = () => {
    const navigate = useNavigate();
    const { setAuthRedirect } = useRedirectActions();
    const { tokens } = useAppSelector((state) => state.authReducer);

    const handleClick = () => {
        if (tokens) {
            navigate(AllRouteConstants.user.plan);
        } else {
            setAuthRedirect(AllRouteConstants.user.plan);
            navigate(AllRouteConstants.auth.login);
        }
    }

    return (
        <div className="bv-trading-plans-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>Investment Plans</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>Investment Plans</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-details-area section-padding-100-50 bg-gray-cu">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details-content mb-50">
                                <div className="prix">
                                    <div className="pricing-wrapper clearfix">
                                        <h1 className="pricing-table-title">Investment Plans</h1>
                                        
                                        <div className="pricing-table">
                                            <h3 className="pricing-title">Sapphire</h3>

                                            <div className="price">$1000<sup></sup></div>

                                            <ul className="table-list">
                                                <li>
                                                    <FontAwesomeIcon className="check" icon={icon({ name: 'check', style: 'solid' })} />
                                                    {' '}
                                                    ATS
                                                </li>                    
                                            </ul>

                                            <div className="table-buy">
                                                <p>$1000<sup></sup></p>
                                                <button onClick={handleClick} className="pricing-action">Purchase</button>
                                            </div>
                                        </div>
                                        
                                        <div className="pricing-table">
                                            <h3 className="pricing-title">GOLD</h3>

                                            <div className="price">$10,000<sup></sup></div>

                                            <ul className="table-list">
                                                <li>
                                                    <FontAwesomeIcon className="check" icon={icon({ name: 'check', style: 'solid' })} />
                                                    {' '}
                                                    ATS
                                                </li>                  
                                            </ul>

                                            <div className="table-buy">
                                                <p>$10,000<sup></sup></p>
                                                <button onClick={handleClick} className="pricing-action">Purchase</button>
                                            </div>
                                        </div>

                                        <div className="pricing-table">
                                            <h3 className="pricing-title">PLATINUM</h3>

                                            <div className="price">$50,000<sup></sup></div>

                                            <ul className="table-list">
                                                <li>
                                                    <FontAwesomeIcon className="check" icon={icon({ name: 'check', style: 'solid' })} />
                                                    {' '}
                                                    ATS
                                                </li>            
                                            </ul>
                                            
                                            <div className="table-buy">
                                                <p>$50,000<sup></sup></p>
                                                <button onClick={handleClick} className="pricing-action">Purchase</button>
                                            </div>
                                        </div>
                                        
                                        <div className="pricing-table">
                                            <h3 className="pricing-title">DIAMOND</h3>

                                            <div className="price">$100,000<sup></sup></div>

                                            <ul className="table-list">
                                                <li>
                                                    <FontAwesomeIcon className="check" icon={icon({ name: 'check', style: 'solid' })} />
                                                    {' '}
                                                    ATS (VIP)
                                                </li>  
                                            </ul>

                                            <div className="table-buy">
                                                <p>$100,000<sup></sup></p>
                                                <button onClick={handleClick} className="pricing-action">Purchase</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    )
}