export const TRADING_PLANS = [
	'None',
	'Sapphire-1000',
	'Gold-10000',
	'Platinum-50000',
	'Diamond-100000',
];
export type TradingPlan =
	| 'None'
	| 'Sapphire-1000'
	| 'Gold-10000'
	| 'Platinum-50000'
	| 'Diamond-100000';

export interface PlanRequest {
	id: string;
	user_id: string;
	plan: Omit<TradingPlan, 'None'>;
}

export interface User {
	phone: string;
	user_id: string;
	email: string;
	email_verified: boolean;
	firstname: string;
	lastname: string;
	avatar: string | null;
	referral_code: string | null;
	city: string | null;
	state: string | null;
	country: string | null;
	address: string | null;
	gender: string;
	date_of_birth: string | null;
	balance: number;
	demo_balance: number;
	trading_plan: TradingPlan;
	plan_request?: PlanRequest | null;
}

export interface UserProfileInput {
	phone: string;
	firstname: string;
	lastname: string;
	city: string | null;
	state: string | null;
	country: string | null;
	address: string | null;
	gender: string;
	avatar?: File;
}

export interface ChangePasswordInput {
	current: string;
	password: string;
	confirmPassword: string;
}
